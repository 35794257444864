<template>
  <div class="contact">
    <div class="contact-landing">
      <video
          src=""
          type="video/mp4" autoplay loop poster="../assets/hvar.jpg"></video>
      <div class="contact-overlay">
        <div class="contact-inner">
          <h1>Hvar</h1>
          <div class="underline"></div>

          <p><a href="tel:+385918963726">+385 91 896 3726</a></p>
          <p><a href="mailto:boatinghvar@gmail.com">boatinghvar@gmail.com</a></p>
          <p> jurja matijevica 17<br> 21450 hvar</p>
        </div>
      </div>
      <div class="contact-description">
        <div class="contact-desc-right">
          <div class="contactImage"></div>
        </div>
        <div class="contact-desc-left">
          <h4>hvar boating</h4>
          <div class="contact-underline"></div>
          <p>Hvar, a Croatian island in the Adriatic Sea, is a well-known summer vacation resort. Hvar resort is so
            popular because of the surrounding beaches and warm weather, perfect for vacations. Hvar Town proudly boosts
            the best-laid secrets of Croatian cooking along with the chance to explore art galleries, a magnificent
            16th-century fortress, and lastly, an old-fashioned main square anchored by The Renaissance style Hvar
            Cathedral.</p>
          <p>The Hvar Island has numerous popular beaches, including Dubovica Beach, perfect for snorkeling and some
            relaxed time under the pine trees. There are also various boat trips that can take visitors to the
            neighboring Paklinski Islands. Mind you; there are 12 of them. It’s the perfect place for vacations due to
            its warm and pebbly beaches, nearby underground caves, cliff diving, and idyllic villages </p>
          <p>Hvar is best explored via boat tours. You can book a Hvar boat from us to explore the Island. Vacationers
            can also choose between the option of booking for group tours or private tours. You can also choose between
            Hvar boat tours and rentals as per your liking. </p>
          <p class="lasDiv">Get your booking online or visit us at the Hvar Boating Office in the Center of Hvar Town.
            Additionally, you can also leave us an email at BOATINGHVAR@GMAIL.COM, and we will get back to you.</p>
        </div>

      </div>
      <div class="leaflet-custom">
        <LMap :zoom="zoom" :center="center" :maxZoom="16">
          <LTileLayer :url="url"></LTileLayer>
          <LMarker :lat-lng="[43.171951,16.441687]"></LMarker>

        </LMap>
      </div>
      <ContactForm/>
    </div>
  </div>
</template>

<script>
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: 'MyAwesomeMap',
  metaInfo() {
    return {
      title: ' Contact Us With Confidence | We Will Respond ASAP  ',
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: 'Whether you have a question about visiting Blue cave, green cave , Stiniva beach, Palmizana bay,  Hvar boat tours, private boat rentals, pricing or anything else, we are ready to answer all your questions.'
        }

      ],
      link: [
        {rel: 'favicon', href: 'logo.ico'}
      ]
    }
  },
  components: {
    ContactForm,
    LMap,
    LTileLayer,
    LMarker,
  },

  data() {
    return {
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 16,
      center: [43.1729, 16.4411],
      bounds: null,
      name: '',
      email: '',
      title: '',
      message: '',
      errors: [],
    };
  },

  methods: {
    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.title) {
        this.errors.push("Title required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }

      if (!this.errors.length) {
        try {
          emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
              'user_OReXS1Etru6y4xeTBAltW', {
                name: this.name,
                email: this.email,
                title: this.title,
                message: this.message
              })

        } catch (error) {
          console.log({error})
        }
        this.$modal.show(Modal)
        setTimeout(() => {
          this.$modal.hideAll()
        }, 4000);
        this.name = ''
        this.email = ''
        this.title = ''
        this.message = ''

      }

      e.preventDefault();
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendEmail(e) {
      try {
        emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
            'user_OReXS1Etru6y4xeTBAltW', {
              name: this.name,
              email: this.email,
              title: this.title,
              message: this.message
            })

      } catch (error) {
        console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.title = ''
      this.message = ''
    },
  }
}
</script>