<template>
  <div class="accordion" itemscope itemtype="https://schema.org/FAQPage">
    <h1>frequently asked questions</h1>
    <p>These are the most common questions from our guests</p>
    <VsaList v-for="(question, index) in questions" :key="question.id">
      <VsaItem>
        <VsaHeading>
          <span
              :id="`question-${index}`"
              itemprop="name"
          >
            {{ question.title }}
          </span>
        </VsaHeading>

        <VsaContent>
          <div
              itemprop="acceptedAnswer"
              itemscope
              itemtype="https://schema.org/Answer"
              :aria-labelledby="`question-${index}`"
          >
            <p v-html="sanitizeHtml(question.content)" itemprop="text"></p>
          </div>
        </VsaContent>
      </VsaItem>
    </VsaList>
  </div>
</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent
} from 'vue-simple-accordion'
import 'vue-simple-accordion/dist/vue-simple-accordion.css'
import faq from '../data/faq.json'
import DOMPurify from 'dompurify'

export default {
  name: 'FrequentlyAskedQuestions',

  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent
  },

  metaInfo() {
    return {
      title: 'Frequently Asked Questions About Hvar And Boat Tours',
      meta: [
        {name: 'robots', content: 'index,follow'},
        {name: 'description', content: this.metaDescription},
        {property: 'og:title', content: this.pageTitle},
        {property: 'og:description', content: this.metaDescription},
        {property: 'og:type', content: 'website'},
        {property: 'og:url', content: this.currentUrl},
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: this.pageTitle},
        {name: 'twitter:description', content: this.metaDescription}
      ],
      link: [
        {rel: 'canonical', href: this.currentUrl},
        {rel: 'shortcut icon', href: 'logo.ico'}
      ],
      script: [{
        type: 'application/ld+json',
        json: this.getJsonLd()
      }]
    }
  },

  data() {
    return {
      questions: faq,
      pageTitle: 'Frequently Asked Questions About Hvar And Boat Tours',
      metaDescription: 'Hvar Boating is a top tour agency in Hvar. Book now! The best things to do in Hvar. Amazing Hvar boat tours & rentals, famous tour to Blue Cave from Hvar, Pakleni islands, Palmizana beach and visit the most beautiful Hvar beaches'
    }
  },

  computed: {
    currentUrl() {
      return `https://www.hvarboating.com${this.$route.path}`
    }
  },

  methods: {
    sanitizeHtml(content) {
      return DOMPurify.sanitize(content, {
        ALLOWED_TAGS: ['a', 'br', 'p', 'strong', 'em'],
        ALLOWED_ATTR: ['href', 'target', 'rel']
      })
    },

    getJsonLd() {
      return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": this.questions.map(question => ({
          "@type": "Question",
          "name": question.title,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": this.stripHtml(question.content)
          }
        }))
      }
    },

    stripHtml(html) {
      const tmp = document.createElement('div')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    }
  }
}
</script>