<template>
  <header class="menuBar">
    <b-navbar
        toggleable="lg"
        type="dark"
        variant="info"
        class="navbar-custom fixed-top bg-info"
        :class="navbarClasses"
        aria-label="Main navigation"
    >
      <b-navbar-brand id="logo-desktop" :to="{path:'/'}" aria-label="Hvar Boating Home">
        <img
            src="../assets/logo.png"
            alt="Hvar Boating Logo"
            width="120"
            height="40"
        >
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse">
        <div class="hamburger-lines">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="mx-auto justify-content-around w-100 navbarToCenter">
          <b-nav-item
              v-for="item in navigationItems"
              :key="item.path"
              :to="{path: item.path}"
              :active="$route.path === item.path"
          >
            {{ item.label }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
export default {
  name: 'Navigation',

  data() {
    return {
      scrollPosition: null,
      scrollThrottleTimeout: null,
      navigationItems: [
        { path: '/blue-caves-croatia', label: 'Group Boat Tours' },
        { path: '/hvar-boat-tours-top-rated-adventures', label: 'Private Boat Tours' },
        { path: '/hvar-boat-hire-adriatics-hidden-gems', label: 'Boat Rentals' },
        { path: '/from-split-to-Hvar-transfer', label: 'Boat Transfers' },
        { path: '/hvar-weather', label: 'Weather' },
        { path: '/frequently-asked-questions', label: 'FAQ' },
        { path: '/contact', label: 'Contact' }
      ]
    }
  },

  computed: {
    navbarClasses() {
      return {
        'change_color': this.scrollPosition > 170,
        'chng_color': this.scrollPosition < 170
      }
    }
  },

  methods: {
    updateScroll() {
      if (!this.scrollThrottleTimeout) {
        this.scrollThrottleTimeout = setTimeout(() => {
          this.scrollPosition = window.scrollY
          this.scrollThrottleTimeout = null
        }, 16)
      }
    }
  },

  mounted() {
    this.scrollPosition = window.scrollY
    window.addEventListener('scroll', this.updateScroll, { passive: true })
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll)
    if (this.scrollThrottleTimeout) {
      clearTimeout(this.scrollThrottleTimeout)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>