<template>
  <footer class="footer">
    <div class="footer-inner">
      <div class="footer-row">
        <div class="footer-contact">
          <div class="footer-contact-details" itemscope itemtype="http://schema.org/LocalBusiness">
            <p class="footer-contact-details-top-par">
              <a href="tel:+385918973053" itemprop="telephone" aria-label="Phone number">
                +385 91 897 3053
              </a>
            </p>
            <p>
              <a href="mailto:boatinghvar@gmail.com" itemprop="email" aria-label="Email address">
                boatinghvar@gmail.com
              </a>
            </p>
            <address itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
              <span itemprop="streetAddress">jurja matijevica 17</span><br>
              <span itemprop="postalCode">21450</span>
              <span itemprop="addressLocality">hvar</span>
            </address>
          </div>
        </div>

        <div class="footer-social" aria-label="Social Media Links">
          <a
              href="https://www.instagram.com/hvarboating"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Follow us on Instagram"
              class="social-link"
          >
            <i class="fab fa-instagram-square" aria-hidden="true"></i>
          </a>
          <a
              href="https://www.facebook.com/boatinghvar"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Follow us on Facebook"
              class="social-link"
          >
            <i class="fab fa-facebook-square" aria-hidden="true"></i>
          </a>
        </div>
      </div>

      <nav class="footer-menu" aria-label="Footer Navigation">
        <ul class="footer-nav-list">
          <li v-for="item in footerNavItems" :key="item.path" class="footer-nav-item">
            <router-link :to="item.path" class="nav-link">{{ item.label }}</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',

  data() {
    return {
      footerNavItems: [
        { path: '/blue-caves-croatia', label: 'Group Boat Tours' },
        { path: '/boat-tours', label: 'Private Boat Tours' },
        { path: '/hvar-boat-rental', label: 'Boat Rentals' },
        { path: '/from-split-to-Hvar-transfer', label: 'Boat Transfers' }
      ]
    }
  }
}
</script>

<style scoped>
.footer {
  background-color: rgb(41, 59, 95);
  padding: 3rem 0;
  color: white;
  width: 100%;
  box-sizing: border-box;
}

.footer-inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footer-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.footer-contact-details {
  font-family: 'Nunito Sans', sans-serif;
}

.footer-contact-details a {
  color: white;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.footer-contact-details a:hover {
  opacity: 0.8;
}

.footer-contact-details-top-par {
  margin-bottom: 0.5rem;
}

address {
  font-style: normal;
  margin-top: 0.5rem;
}

.footer-social {
  display: flex;
  gap: 1rem;
}

.social-link {
  color: white;
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.social-link:hover {
  transform: scale(1.1);
  color: white;
}

.footer-menu {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 2rem;
}

.footer-nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.footer-nav-item .nav-link {
  color: white;
  text-decoration: none;
  transition: opacity 0.3s ease;
  padding: 0.5rem;
}

.footer-nav-item .nav-link:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .footer-row {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
  }

  .footer-nav-list {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}
</style>