// ContactForm.vue
<template>
  <div class="landing-contact">
    <div class="contact-inner">
      <h3>Enquire</h3>
      <div v-if="errors.length" class="error-container">
        <b>Please correct the following error(s):</b>
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </div>
      <div class="contact-line"></div>

      <form @submit.prevent="checkForm">
        <input
            type="text"
            v-model="name"
            name="name"
            class="name"
            placeholder="NAME"
        >
        <input
            type="email"
            v-model="email"
            name="email"
            class="email"
            placeholder="E-MAIL"
        >
        <input
            type="text"
            v-model="title"
            name="title"
            class="title"
            placeholder="TITLE"
        >
        <textarea
            v-model="message"
            name="message"
            class="content"
            cols="30"
            rows="10"
            placeholder="ENQUIRY"
        ></textarea>
        <button type="submit">Send</button>
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal"

export default {
  name: 'ContactForm',

  data() {
    return {
      name: '',
      email: '',
      title: '',
      message: '',
      errors: []
    }
  },

  methods: {
    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.title) {
        this.errors.push("Title required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }

      if (!this.errors.length) {
        try {
          emailjs.sendForm(
              'service_8xsw9aa',
              'template_19ompn2',
              e.target,
              'user_OReXS1Etru6y4xeTBAltW'
          );

          this.$modal.show(Modal);
          setTimeout(() => {
            this.$modal.hideAll();
          }, 4000);

          this.resetForm();
        } catch (error) {
          console.error('Form submission error:', error);
        }
      }

      e.preventDefault();
    },

    resetForm() {
      this.name = '';
      this.email = '';
      this.title = '';
      this.message = '';
    },

    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
}
</script>
