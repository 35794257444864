<template>
  <div class="group-tour">
    <div class="group-tour-landing" v-for="tour in groupTour" :key="tour.id">
      <video
          src="../assets/cover.mp4"
          type="video/mp4"
          poster="../assets/bluecave2.jpg"
          autoplay
          loop
          muted
          playsinline
          preload="auto"
          class="group-video"
      ></video>

      <div class="group-tour-overlay">
        <div class="group-tour-inner">
          <h1>{{ tour.title }}</h1>
          <p>{{ tour.subtitle }}</p>
          <button
              @click="addParamsToUrl(448336)"
              class="book-button">
            Book now!
          </button>
        </div>
      </div>

      <div class="group-tour-details">
        <div class="group-tour-left-details">
          <ul>
            <li class="group-tour-title-no-underline"><h4>Tour Summary</h4></li>
            <li v-for="item in tour.tourSummary" :key="item.id">
              <p v-html="item.tourSummaryItem"></p>
            </li>
          </ul>
        </div>

        <div class="group-tour-right-details">
          <ul>
            <li class="group-tour-title-no-underline"><h4>Additional information</h4></li>
            <li v-for="addItem in tour.additionalInformation" :key="addItem.key">
              <p v-html="addItem.addInfItem"></p>
            </li>
          </ul>

          <ul class="group-tour-highlight">
            <li class="group-tour-title-no-underline"><h4>TOUR HIGHLIGHTS</h4></li>
            <li><p v-html="tour.tourHighlights"></p></li>
          </ul>
        </div>
      </div>

      <div class="group-tour-description">
        <div class="group-tour-desc-right">
          <div
              class="descImage mobile-image"
              :style="getBackgroundStyle(tour.descImageOne)"
              role="img"
              :aria-label="'Tour image 1'"
          ></div>
          <div
              class="descImage"
              :style="getBackgroundStyle(tour.descImageTwo)"
              role="img"
              :aria-label="'Tour image 2'"
          ></div>
        </div>

        <div class="group-tour-desc-left">
          <h4>Blue cave boat Tour Details</h4>
          <div class="group-tour-underline"></div>
          <p v-html="tour.description"></p>
        </div>
      </div>

      <div class="group-tour-gallery">
        <agile :initial-slide="1" :options="myOptions">
          <img
              v-for="photo in tour.gallery"
              :key="photo.id"
              class="slide"
              :src="coverImage(photo.img)"
              alt="blue cave tour from hvar"
              loading="lazy"
          >
          <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
          <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
        </agile>
      </div>

      <div class="backlink-title">
        <h4>group tours are not your cup of tea?</h4>
      </div>

      <div class="backlink">
        <div class="backlink-inner box">
          <router-link to="/hvar-boat-tours-top-rated-adventures">
            <div class="privateImage image">
              <div class="overlay-title">
                <h4 class="hover-title">Private boat tours</h4>
                <div class="hover-title-line"></div>
                <p>Enjoy unique tailored speed boat tours and discover Blue cave, the best hvar beaches, zlatni rat beach and amazing pakleni islands</p>
              </div>
              <h4 class="title">Private boat tours</h4>
            </div>
          </router-link>
        </div>

        <div class="backlink-inner box">
          <router-link to="/hvar-boat-hire-adriatics-hidden-gems">
            <div class="rentalImage image">
              <div class="overlay-title">
                <h4 class="hover-title">hvar boat rental</h4>
                <div class="hover-title-line"></div>
                <p>Rent a boat with crew in Hvar and explore maze of pakleni islands, unique blue cave Croatia, or one of the best beaches in Europe Stiniva</p>
              </div>
              <h4 class="title">hvar boat rental</h4>
            </div>
          </router-link>
        </div>
      </div>

      <ContactForm/>
    </div>
  </div>
</template>

<script>
import groupToursData from '@/data/groupToursData.json'
import { VueAgile } from 'vue-agile'
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal"
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: 'GroupTourDetails',

  components: {
    ContactForm,
    agile: VueAgile,
  },

  metaInfo() {
    return {
      title: 'Blue Cave Croatia Boat Tour: Discover the Magical Blue Cave and Nearby Attractions',
      meta: [
        { name: 'robots', content: 'index,follow,max-image-preview:large' },
        {
          name: 'description',
          content: 'Embark on an unforgettable adventure exploring the mesmerizing Blue Cave Croatia and its stunning surroundings. Visit the Green Cave, relax at Stiniva Beach, and unwind at Palmižana Beach on the Pakleni Islands. Book your boat tour today!'
        },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'Blue Cave Croatia Boat Tour | Hvar Boating' },
        { property: 'og:description', content: 'Explore the magical Blue Cave Croatia with our expert guides. Visit Green Cave, Stiniva Beach, and Palmižana Beach. Book your unforgettable Croatian adventure now!' },
        { property: 'og:image', content: 'https://www.hvarboating.com/images/bluecave.jpg' },
        { property: 'og:url', content: 'https://www.hvarboating.com/blue-caves-croatia' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'keywords', content: 'blue cave croatia, hvar boat tours, stiniva beach, green cave, pakleni islands, palmizana beach' }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.hvarboating.com/blue-caves-croatia' },
        { rel: 'icon', type: 'image/x-icon', href: '/logo.ico' },
        { rel: 'preload', as: 'video', href: '../assets/cover.webm', type: 'video/webm' },
        { rel: 'preload', as: 'video', href: '../assets/cover.mp4', type: 'video/mp4' }
      ],
      script: [{
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@type": "TourPackage",
          "name": "Blue Cave Croatia Boat Tour",
          "description": "Witness the magnificence of the Blue Cave on the Biševo island. Take a dip in the emerald waters of the Green Cave and float in Stiniva Cove. Explore the thriving underwater world of Vis island and swim inside the amazing Budikovac Blue Lagoon.",
          "image": [
            "https://www.hvarboating.com/images/bluecave.jpg",
            "https://www.hvarboating.com/images/greencave.jpg",
            "https://www.hvarboating.com/images/stiniva.jpg"
          ],
          "provider": {
            "@type": "TravelAgency",
            "name": "Hvar Boating",
            "image": "https://www.hvarboating.com/images/logo.png",
            "priceRange": "$$$",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Jurja Matijevica 17",
              "addressLocality": "Hvar",
              "postalCode": "21450",
              "addressCountry": "HR"
            },
            "telephone": "+385918973053",
            "email": "boatinghvar@gmail.com",
            "sameAs": [
              "https://www.facebook.com/boatinghvar",
              "https://www.instagram.com/hvarboating"
            ]
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.hvarboating.com/blue-caves-croatia",
            "priceCurrency": "EUR",
            "price": "70",
            "priceValidUntil": "2025-11-01",
            "availability": "https://schema.org/LimitedAvailability",
            "validFrom": "2024-01-01"
          },
          "itinerary": {
            "@type": "ItemList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Blue Cave Biševo",
                "description": "Experience the mesmerizing blue light phenomenon in the Blue Cave"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Green Cave",
                "description": "Swim in the emerald waters of the Green Cave"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Stiniva Beach",
                "description": "Visit the award-winning Stiniva beach"
              },
              {
                "@type": "ListItem",
                "position": 4,
                "name": "Palmižana Beach",
                "description": "Relax at the beautiful Palmižana Beach on Pakleni Islands"
              }
            ]
          },
          "tourDuration": "PT8H",
          "tourLanguage": ["en", "hr"],
          "maximumAttendeeCapacity": 12,
          "departureTime": "09:00",
          "amenityFeature": [

            {
              "@type": "LocationFeatureSpecification",
              "name": "Snorkeling Equipment",
              "value": true
            },
            {
              "@type": "LocationFeatureSpecification",
              "name": "Safety Equipment",
              "value": true
            }
          ],
          "isAccessibleForFree": false,
          "additionalProperty": [
            {
              "@type": "PropertyValue",
              "name": "Tour Type",
              "value": "Group Tour"
            },
            {
              "@type": "PropertyValue",
              "name": "Boat Type",
              "value": "Speed Boat"
            }
          ]
        }
      }]

    }
  },

  data() {
    return {
      groupTour: groupToursData,
      videoLoaded: false,
      videoError: false,
      observer: null,
      imageLoaded: {},
      videoUrl: "https://hvarboating.s3.eu-central-003.backblazeb2.com/cover(1).webm",
      name: '',
      email: '',
      title: '',
      message: '',
      errors: [],
      myOptions: {
        navButtons: false,
        responsive: [
          {
            breakpoint: 350,
            settings: {
              dots: false,
              navButtons: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              navButtons: true
            }
          },
          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              dots: true,
              infinite: false
            }
          }
        ]
      }
    }
  },

  mounted() {
    this.setupVideoOptimization();
    this.setupIntersectionObserver();
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  methods: {
    setupVideoOptimization() {
      // Since we're using v-for, tourVideo will be an array
      const videos = this.$refs.tourVideo;
      if (!videos) return;

      // Handle both single element and array cases
      const videoElements = Array.isArray(videos) ? videos : [videos];

      videoElements.forEach(video => {
        if (!video) return;

        video.classList.add('loading');

        video.addEventListener('loadeddata', () => {
          this.videoLoaded = true;
          video.classList.remove('loading');
          video.classList.add('loaded');
          if (video.paused) {
            this.playVideo(video);
          }
        });

        video.addEventListener('error', (e) => {
          console.error('Video loading error:', e);
          this.videoError = true;
          video.classList.remove('loading');
          video.style.display = 'none';
        });

        // Add load timeout
        setTimeout(() => {
          if (!this.videoLoaded) {
            video.classList.remove('loading');
          }
        }, 5000);
      });
    },


    setupIntersectionObserver() {
      if ('IntersectionObserver' in window) {
        this.observer = new IntersectionObserver(
            (entries) => {
              entries.forEach(entry => {
                const video = entry.target;
                if (entry.isIntersecting) {
                  if (video.paused && this.videoLoaded) {
                    this.playVideo();
                  }
                } else {
                  if (!video.paused) {
                    video.pause();
                  }
                }
              });
            },
            {
              threshold: 0.25
            }
        );

        const video = this.$refs.tourVideo;
        if (video) {
          this.observer.observe(video);
        }
      }
    },

    playVideo() {
      const video = this.$refs.tourVideo;
      if (video) {
        video.play().catch(() => {
          console.warn('Video autoplay prevented');
          this.videoError = true;
        });
      }
    },

    addParamsToUrl(activityId) {
      const params = new URLSearchParams({
        partner_id: '1001SHJ',
        partner_ticketing_activity_ids: activityId,
      });
      window.location.href = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
    },

    getBackgroundStyle(path) {
      return {
        background: `url(${this.coverImage(path)}) no-repeat center center`,
        backgroundSize: 'cover'
      }
    },

    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.title) {
        this.errors.push("Title required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }

      if (!this.errors.length) {
        try {
          emailjs.sendForm(
              'service_8xsw9aa',
              'template_19ompn2',
              e.target,
              'user_OReXS1Etru6y4xeTBAltW'
          );

          this.$modal.show(Modal);
          setTimeout(() => {
            this.$modal.hideAll();
          }, 4000);

          this.resetForm();
        } catch (error) {
          console.error('Form submission error:', error);
        }
      }

      e.preventDefault();
    },

    resetForm() {
      this.name = '';
      this.email = '';
      this.title = '';
      this.message = '';
    },

    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    coverImage(path) {
      return require(`@/${path}`);
    },

    onImageLoad(photoId) {
      this.$set(this.imageLoaded, photoId, true);
    }
  }
}
</script>