<template>
  <div class="boat-transfers">
    <div class="boat-transfers-landing">
      <div v-if="videoLoading" class="video-loader">Loading...</div>
      <video
          ref="transferVideo"
          :src="require('@/assets/tornado.mp4')"
          type="video/mp4"
          :poster="require('@/assets/boatTransfer.jpg')"
          @loadstart="videoLoading = true"
          @canplay="handleVideoLoad"
          autoplay
          muted
      ></video>

      <div class="boat-transfers-overlay">
        <div class="boat-transfers-inner">
          <h1>Fast and Stress-Free: Speedboat Transfer from Split to Hvar</h1>
          <p>Get to / from Hvar in the most comfortable way</p>
        </div>
      </div>

      <div class="boat-transfers-details">
        <div class="boat-transfers-left-details">
          <ul>
            <li class="boat-transfers-title-no-underline"><h4>to/from Hvar</h4></li>
            <li v-for="(route, index) in transferRoutes" :key="index">
              <p>{{ route.destination }}</p>
            </li>
          </ul>
        </div>
        <div class="boat-transfers-middle-details">
          <ul>
            <li class="boat-transfers-title-no-underline"><h4>standard</h4></li>
            <li v-for="(route, index) in transferRoutes" :key="index">
              <p>{{ route.standardPrice }} eur</p>
            </li>
          </ul>
        </div>
        <div class="boat-transfers-right-details">
          <ul>
            <li class="boat-transfers-title-no-underline"><h4>Deluxe</h4></li>
            <li v-for="(route, index) in transferRoutes" :key="index">
              <p>{{ route.deluxePrice }} eur</p>
            </li>
          </ul>
        </div>
      </div>

      <div class="boat-transfers-description">
        <div class="boat-transfers-desc-right">
          <img
              :src="require('@/assets/rentCover.jpg')"
              alt="Hvar boat rental services"
              loading="lazy"
          >
          <img
              :src="require('@/assets/transfer.webp')"
              alt="Hvar boat transfer services"
              loading="lazy"
          >
          <img
              id="image_three"
              :src="require('@/assets/transfer3.jpg')"
              alt="Hvar boat transfer services"
              loading="lazy"
          >

        </div>
        <div class="boat-transfers-desc-left">
          <h4>speed boat hvar transfers details</h4>
          <div class="boat-transfers-underline"></div>
          <div>
            <p>
              Traveling from Split to Hvar is an unforgettable experience, especially when you choose a speedboat transfer.With the sparkling Adriatic Sea as your backdrop, this journey combines convenience, breathtaking views, and the excitement of island-hopping.
              <br/>
              <br/>
              Why Choose a Speedboat Transfer from Split to Hvar?
              <br/>
              <br/>
              Speed and Efficiency
              <br/>
              <br/>
              A speedboat transfer takes only 1 to 1.5 hours, making it the fastest way to reach Hvar. Skip the long ferry lines and get straight to enjoying your island getaway.
              <br/>
              <br/>
              Comfort and Convenience
              <br/>
              <br/>
              Modern speedboats are equipped with comfortable seating and amenities, ensuring a relaxing journey. Whether traveling solo, with family, or in a group, this option caters to your needs.
              <br/><br/>
              Spectacular Scenery
              <br/><br/>
              The ride offers stunning views of the Dalmatian coast, the shimmering Adriatic waters, and the nearby islands. It's more than just a transfer—it's an adventure.
              <br/><br/>
              Direct Transfers from Split Airport
              <br/><br/>
              For those arriving at Split Airport, private speedboats can pick you up directly and take you to Hvar without the hassle of buses, taxis, or ferries.
              <br/><br/>
              Benefits of a Private Speedboat Transfer
              <br/><br/>
              • Privacy: Travel exclusively with your group—perfect for couples, families, or friends.
              <br/>
              • Flexibility: Choose your departure time and plan the trip at your pace.
              <br/>
              • Freedom: Stop along the way to explore hidden coves, swim, or enjoy the view.
              <br/>
              • Stress-Free Travel: Avoid delays and crowded ferries, starting your holiday relaxed.
              <br/><br/>
              What to Expect on Your Journey
              <br/><br/>
              The speedboat transfer begins in Split, offering a smooth ride across the Adriatic. Along the way, you'll
              pass
              picturesque islands, feel the fresh sea breeze, and take in the incredible coastal scenery. Upon arrival in Hvar, you're ready to dive into its vibrant nightlife, serene beaches, and charming villages.
              <br/><br/>
              How to Book Your Split to Hvar Speedboat Transfer
              <br/><br/>
              Booking is simple and hassle-free. Whether you want a private transfer or a group option, flexible departure times are available to suit your schedule.Begin Your Hvar Adventure Today A speedboat transfer from Split to Hvar is the ultimate way to kick off your Croatian island experience. Enjoy  the perfect blend of speed, comfort, and natural beauty as you travel across the Adriatic Sea. Book your transfer now and start your journey to Hvar in style!
            </p>
          </div>
        </div>
      </div>

      <ContactForm/>
    </div>

  </div>
</template>

<script>
import emailjs from 'emailjs-com'

import ContactForm from "@/components/ContactForm.vue";

export default {
  name: 'BoatTransfers',

  components: {
    ContactForm,

  },

  metaInfo() {
    return {
      title: 'Hvar Transfers | Transfers From Split To Hvar',
      titleTemplate: '%s | Fast & Comfortable',
      meta: [
        { name: 'robots', content: 'index,follow' },
        { name: 'description', content: this.metaDescription },
        { property: 'og:title', content: 'Hvar Transfers | Fast Boat Service from Split' },
        { property: 'og:description', content: this.metaDescription },
        { property: 'og:type', content: 'service' },
        { property: 'og:image', content: require('@/assets/boatTransfer.jpg') },
        { property: 'og:url', content: this.currentUrl },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Hvar Transfers | Speedboat Service' },
        { name: 'twitter:description', content: this.metaDescription }
      ],
      link: [
        { rel: 'canonical', href: this.currentUrl },
        { rel: 'favicon', href: 'logo.ico' }
      ],
      script: [{
        type: 'application/ld+json',
        json: this.getJsonLd()
      }]
    }
  },

  data() {
    return {
      videoLoading: true,
      showModal: false,
      isSubmitting: false,
      transferRoutes: [
        { destination: 'split airport', standardPrice: 450, deluxePrice: 900 },
        { destination: 'split town', standardPrice: 430, deluxePrice: 850 },
        { destination: 'bol', standardPrice: 430, deluxePrice: 850 },
        { destination: 'vis town', standardPrice: 380, deluxePrice: 500 },
        { destination: 'vela luka', standardPrice: 450, deluxePrice: 850 },
        { destination: 'korcula', standardPrice: 580, deluxePrice: 900 },
        { destination: 'dubrovnik', standardPrice: 1300, deluxePrice: 2100 }
      ],
      formData: {
        name: '',
        email: '',
        title: '',
        message: ''
      },
      errors: [],
      metaDescription: 'The best way to get from Split to Hvar or any other destination is by Speed boat. Our speed boats with crew will give you the best possible experience on the sea. Book now!'
    }
  },

  computed: {
    currentUrl() {
      return `https://www.hvarboating.com${this.$route.path}`
    },

    transferDescription() {
      return `
        <p>
          Traveling from Split to Hvar is an unforgettable experience, especially when you choose a speedboat transfer.With the sparkling Adriatic Sea as your backdrop, this journey combines convenience, breathtaking views, and the excitement of island-hopping.
          <br/>
          <br/>
          Why Choose a Speedboat Transfer from Split to Hvar?
          <br/>
          <br/>
          Speed and Efficiency
          <br/>
          <br/>
          A speedboat transfer takes only 1 to 1.5 hours, making it the fastest way to reach Hvar. Skip the long ferry lines and get straight to enjoying your island getaway.
          <br/>
          <br/>
          Comfort and Convenience
          <br/>
          <br/>
          Modern speedboats are equipped with comfortable seating and amenities, ensuring a relaxing journey. Whether traveling solo, with family, or in a group, this option caters to your needs.
          <br/><br/>
          Spectacular Scenery
          <br/><br/>
          The ride offers stunning views of the Dalmatian coast, the shimmering Adriatic waters, and the nearby islands. It's more than just a transfer—it's an adventure.
          <br/><br/>
           Direct Transfers from Split Airport
          <br/><br/>
          For those arriving at Split Airport, private speedboats can pick you up directly and take you to Hvar without the hassle of buses, taxis, or ferries.
          <br/><br/>
          Benefits of a Private Speedboat Transfer
          <br/><br/>
          • Privacy: Travel exclusively with your group—perfect for couples, families, or friends.
          <br/>
          • Flexibility: Choose your departure time and plan the trip at your pace.
          <br/>
          • Freedom: Stop along the way to explore hidden coves, swim, or enjoy the view.
          <br/>
          • Stress-Free Travel: Avoid delays and crowded ferries, starting your holiday relaxed.
          <br/><br/>
          What to Expect on Your Journey
          <br/><br/>
          The speedboat transfer begins in Split, offering a smooth ride across the Adriatic. Along the way, you'll
          pass
          picturesque islands, feel the fresh sea breeze, and take in the incredible coastal scenery. Upon arrival in Hvar, you're ready to dive into its vibrant nightlife, serene beaches, and charming villages.
          <br/><br/>
          How to Book Your Split to Hvar Speedboat Transfer
          <br/><br/>
          Booking is simple and hassle-free. Whether you want a private transfer or a group option, flexible departure times are available to suit your schedule.Begin Your Hvar Adventure Today A speedboat transfer from Split to Hvar is the ultimate way to kick off your Croatian island experience. Enjoy  the perfect blend of speed, comfort, and natural beauty as you travel across the Adriatic Sea. Book your transfer now and start your journey to Hvar in style!
        </p>
      `
    },

    getJsonLd() {
      return {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Hvar Speedboat Transfer Service",
        "description": this.metaDescription,
        "provider": {
          "@type": "LocalBusiness",
          "name": "Hvar Boating",
          "image": "https://www.hvarboating.com/assets/boatTransfer.jpg",
          "priceRange": "€€€",
          "telephone": "+385918973053",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Jurja Matijevica 17",
            "addressLocality": "Hvar",
            "postalCode": "21450",
            "addressCountry": "Croatia"
          }
        },
        "areaServed": {
          "@type": "Place",
          "name": "Hvar and surrounding islands"
        },
        "offers": this.transferRoutes.map(route => ({
          "@type": "Offer",
          "name": `Transfer to ${route.destination}`,
          "priceSpecification": {
            "@type": "PriceSpecification",
            "price": route.standardPrice,
            "priceCurrency": "EUR"
          },
          "url": route.bookingUrl || "https://www.hvarboating.com", // Add a fallback URL if specific booking URLs are not available
        }))
      };
    }

  },

  methods: {
    handleVideoLoad() {
      this.videoLoading = false
    },

    async handleSubmit(event) {
      if (this.isSubmitting) return

      this.errors = []
      this.isSubmitting = true

      try {
        if (this.validateForm()) {
          await this.sendEmail(event.target)
          this.showSuccessModal()
          this.resetForm()
        }
      } catch (error) {
        console.error('Form submission error:', error)
        this.errors.push('An error occurred. Please try again later.')
      } finally {
        this.isSubmitting = false
      }
    },

    validateForm() {
      const { name, email, title, message } = this.formData

      if (!name) this.errors.push("Name required.")
      if (!email) {
        this.errors.push('Email required.')
      } else if (!this.validEmail(email)) {
        this.errors.push('Valid email required.')
      }
      if (!title) this.errors.push("Title required.")
      if (!message) this.errors.push("Message required.")

      return this.errors.length === 0
    },

    validEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },

    async sendEmail(form) {
      const { name, email, title, message } = this.formData

      return emailjs.sendForm(
          'service_8xsw9aa',
          'template_19ompn2',
          form,
          'user_OReXS1Etru6y4xeTBAltW',
          { name, email, title, message }
      )
    },

    showSuccessModal() {
      this.showModal = true
      setTimeout(this.closeModal, 4000)
    },

    closeModal() {
      this.showModal = false
    },

    resetForm() {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = ''
      })
    }
  }
}
</script>

<style scoped>
/* Only adding styles for new features */
.video-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.error-container {
  margin-bottom: 1rem;
}
</style>