<template>
  <div class="home">
    <div class="home-landing">
      <video
          v-if="!reducedMotion && !videoError"
          ref="videoRef"
          class="home-video"
          src="../assets/southvideo.mp4"
          poster="../assets/redrocks3.jpg"
          autoplay
          loop
          muted
          playsinline
          preload="auto"
          @loadeddata="handleVideoLoad"
          @error="handleVideoError"
      ></video>
      <img
          v-else
          src="../assets/redrocks3.jpg"
          alt="Hvar Boating Background"
          class="home-video"
      />

      <div class="home-overlay">
        <div class="home-inner">
          <h1>Hvar boat tours & rentals</h1>
          <p>for your exceptional summer</p>
        </div>
      </div>

      <div class="home-small-bar">
        <p>So, you want to know more about, the best speed boat tours, Hvar boat rentals and Transfers from Hvar? You came to the right place!</p>
      </div>

      <div class="home-landing-second">
        <div class="landing-group-tours box">
          <router-link to="/blue-caves-croatia">
            <div class="images image">
              <div class="overlay-title">
                <h4 class="hover-title">group boat tours</h4>
                <div class="hover-title-line"></div>
                <p>Discover Blue cave, green cave, Stiniva bay and palmizana beach</p>
              </div>
              <h4 class="title">group boat tours</h4>
            </div>
          </router-link>
        </div>

        <div class="landing-private-tours box">
          <router-link to="/hvar-boat-tours-top-rated-adventures">
            <div class="images image">
              <div class="overlay-title">
                <h4 class="hover-title">private boat tours</h4>
                <div class="hover-title-line"></div>
                <p>Enjoy unique tailored speed boat tours and discover Blue cave, the best hvar beaches, zlatni rat beach and amazing pakleni islands</p>
              </div>
              <h4 class="title">private boat tours</h4>
            </div>
          </router-link>
        </div>

        <div class="landing-boat-rentals box">
          <router-link to="/hvar-boat-hire-adriatics-hidden-gems">
            <div class="images image">
              <div class="overlay-title">
                <h4 class="hover-title">boat rentals</h4>
                <div class="hover-title-line"></div>
                <p>Rent a boat with crew in Hvar and explore maze of pakleni islands, unique blue cave, or one of the best beaches in Europe Stiniva</p>
              </div>
              <h4 class="title">boat rentals</h4>
            </div>
          </router-link>
        </div>

        <div class="landing-transfers box">
          <router-link to="/from-split-to-Hvar-transfer">
            <div class="images image">
              <div class="overlay-title">
                <h4 class="hover-title">Boat transfer</h4>
                <div class="hover-title-line"></div>
                <p>Speed boat trasfers from/to Split airport</p>
              </div>
              <h4 class="title">Boat transfers</h4>
            </div>
          </router-link>
        </div>
      </div>

      <contact-form/>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import ContactForm from '@/components/ContactForm.vue'
// Import the assets directly
import videoSource from '@/assets/southvideo.mp4'
import posterImage from '@/assets/redrocks3.jpg'

export default {
  name: 'Home',

  components: {
    ContactForm
  },

  setup() {
    const videoRef = ref(null)
    const videoError = ref(false)
    const reducedMotion = ref(false)

    onMounted(() => {
      reducedMotion.value = window.matchMedia('(prefers-reduced-motion: reduce)').matches

      // Set up intersection observer for video
      if (videoRef.value && window.IntersectionObserver) {
        const observer = new IntersectionObserver(
            (entries) => {
              entries.forEach(entry => {
                if (entry.isIntersecting) {
                  if (videoRef.value.paused) {
                    videoRef.value.play().catch(() => {
                      videoError.value = true
                    })
                  }
                } else {
                  if (!videoRef.value.paused) {
                    videoRef.value.pause()
                  }
                }
              })
            },
            { threshold: 0.1 }
        )
        observer.observe(videoRef.value)

        return () => observer.disconnect()
      }
    })

    return {
      videoRef,
      videoError,
      reducedMotion,
      videoSrc: videoSource,
      posterImage,
      handleVideoLoad: () => {
        if (videoRef.value) {
          videoRef.value.play().catch(() => {
            videoError.value = true
          })
        }
      },
      handleVideoError: () => {
        videoError.value = true
      }
    }
  },

  metaInfo() {
    return {
      title: 'Hvar Boat Tours & Rentals | Best Croatia Blue Cave Tours | Hvar Boating',
      meta: [
        { name: 'robots', content: 'index,follow,max-image-preview:large' },
        {
          name: 'description',
          content: 'Premium boat tours and rentals in Hvar, Croatia. Visit Blue Cave, Pakleni Islands, and hidden beaches. Group tours, private charters, and speedboat transfers available. Top-rated experiences with professional crew.'
        },
        { name: 'keywords', content: 'hvar boat tours, blue cave croatia, pakleni islands tour, hvar boat rental, split to hvar transfer, private boat tour hvar, green cave tour, stiniva bay, croatian islands tour' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'Hvar Boat Tours & Rentals | Premium Croatian Adventures' },
        { property: 'og:description', content: 'Experience the best of Croatian islands with Hvar Boating. Book Blue Cave tours, boat rentals, and private tours. Visit Pakleni Islands, Palmižana beach, and more!' },
        { property: 'og:url', content: 'https://www.hvarboating.com' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'geo.region', content: 'HR-17' },
        { name: 'geo.position', content: '43.171989;16.441758' },
        { name: 'ICBM', content: '43.171989, 16.441758' }
      ],
      script: [{
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": "TourOperator",
              "@id": "https://www.hvarboating.com/#organization",
              "name": "Hvar Boating",
              "url": "https://www.hvarboating.com",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.hvarboating.com/logo.png"
              },
              "description": "Premium boat tours and rentals in Hvar, Croatia offering Blue Cave tours, private charters, and speedboat transfers.",
              "areaServed": {
                "@type": "Place",
                "name": "Hvar and Dalmatian Islands",
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": 43.171989,
                  "longitude": 16.441758
                }
              },
              "priceRange": "€€-€€€",
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
                ],
                "opens": "09:00",
                "closes": "20:00"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+385918973053",
                "contactType": "customer service",
                "availableLanguage": ["English", "Croatian", "German"]
              }
            },
            {
              "@type": "TouristTrip",
              "name": "Blue Cave and Five Islands Tour",
              "description": "Visit the magical Blue Cave, Green Cave, Stiniva Bay, and Pakleni Islands in one day",
              "touristType": ["Couples", "Families", "Groups"],
              "subjectOf": {
                "@type": "Event",
                "name": "Blue Cave and Five Islands Guided Tour",
                "description": "A guided tour to the Blue Cave, Green Cave, Stiniva Bay, and Pakleni Islands.",
                "eventStatus": "https://schema.org/EventScheduled",
                "startDate": "2024-04-01T09:00:00+02:00",
                "endDate": "2024-10-31T17:00:00+02:00",
                "location": {
                  "@type": "Place",
                  "name": "Hvar Port",
                  "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Jurja Matijevica 17",
                    "addressLocality": "Hvar",
                    "postalCode": "21450",
                    "addressCountry": "HR"
                  }
                },
                "organizer": {
                  "@type": "Organization",
                  "name": "Hvar Boating",
                  "url": "https://www.hvarboating.com"
                },
                "image": "https://www.hvarboating.com/assets/bluecave2.jpg",
                "offers": {
                  "@type": "Offer",
                  "price": "85",
                  "priceCurrency": "EUR",
                  "availability": "https://schema.org/InStock",
                  "validFrom": "2024-04-01"
                },
                "performer": {
                  "@type": "Person",
                  "name": "Experienced Tour Guide"
                }
              }
            },
            {
              "@type": "Service",
              "serviceType": "BoatRental",
              "name": "Private Speedboat Rental",
              "description": "Luxury speedboat rentals with professional skipper for custom island tours",
              "provider": {"@id": "https://www.hvarboating.com/#organization"},
              "areaServed": "Dalmatian Coast",
              "offers": {
                "@type": "Offer",
                "price": "600.00",
                "priceCurrency": "EUR",
                "availability": "https://schema.org/InStock"
              }
            },
            {
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "What is the best time to visit Blue Cave?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The best time to visit Blue Cave is between 11 AM and 12 PM when the sunlight creates the most spectacular blue illumination inside the cave."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Do you provide transfer services from Split?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we offer fast and comfortable speedboat transfers from Split to Hvar, with multiple daily departures."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How many people can your boats accommodate?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our boats can accommodate groups of up to 12 people, ensuring a comfortable and enjoyable experience for all passengers."
                  }
                }
              ]
            },
            {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "4.9",
                "bestRating": "5"
              },
              "author": {
                "@type": "Organization",
                "name": "TripAdvisor"
              },
              "publisher": {"@id": "https://www.hvarboating.com/#organization"}
            }
          ]
        }
      }],

      link: [
        { rel: 'canonical', href: 'https://www.hvarboating.com' }
      ]
    }
  }

}
</script>