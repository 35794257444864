<template>
  <div class="boat-tours">
    <div class="boat-tours-landing">
      <div v-if="videoLoading" class="video-loader">Loading...</div>
      <video
          ref="boatVideo"
          :src="require('@/assets/cover.mp4')"
          type="video/mp4"
          :poster="require('@/assets/bluecave.jpg')"
          @loadstart="videoLoading = true"
          @canplay="videoLoading = false"
          autoplay
          loop
      ></video>
      <div class="boat-tours-overlay">
        <div class="boat-tours-inner">
          <h1>Experience the Magic of Hvar: Top-Rated Boat Tours and More</h1>
          <p>tailored made boat tours just for you</p>
        </div>
      </div>
      <div class="boat-tours-small-bar">
        <h2>Exploring Hvar: A Variety of Boat Tours</h2>
        <p>
          Discover the unspoiled Adriatic coast on your own terms! Boat hire in Hvar is the ultimate way to explore
          hidden beaches, crystal-clear bays, and the vibrant marine life of this enchanting island. Whether you're
          drawn to secluded coves or lively coastal towns, we specialize in creating custom boat excursions and private
          yacht charters tailored to your dream adventure.
          <br/> <br/>
          Why Choose Boat Hire in Hvar?
          <br/> <br/>
          Hvar is one of Croatia's most sought-after destinations, and there's no better way to explore it than by boat.
          With boat hire, you can escape the crowds and uncover Hvar's most beautiful and private spots. Swim in
          turquoise waters, relax on deserted beaches, or cruise around the iconic Pakleni Islands.
          <br/><br/>
          Our diverse fleet includes:
          <br/><br/>
          • Small motorboats perfect for day trips.
          <br/>
          • Luxury yachts offering a high-end cruising experience.
          <br/><br/>
          No matter your preferences, we have the perfect boat for your Hvar adventure.
          Discover Hvar's Hidden Gems
          Renting a boat in Hvar unveils the Adriatic's best-kept secrets, including:
          <br/> <br/>
          • Pakleni Islands: A picturesque archipelago ideal for sunbathing, swimming, and snorkeling.
          <br/>
          • Stiniva Cove: A secluded gem surrounded by dramatic cliffs, only accessible by boat.
          <br/>
          • Milna Beach: A peaceful haven with clear waters, perfect for swimming or picnicking.
          <br/> <br/>
          Our well-maintained fleet and professional guidance ensure you'll explore these natural wonders in style and
          comfort.
          Tailored Experiences for Every Traveler
          At our Hvar adventure agency, we design personalized itineraries for all travelers—whether it's your first
          boat rental or you're a seasoned sailor.
          <br/> <br/>
          Our services include:
          <br/> <br/>
          • Private Boat Excursions: Customize your route and discover hidden treasures.
          <br/>
          • Group Boat Trips: Perfect for friends, families, or celebrations.
          <br/>
          • Yacht Charters: Experience ultimate luxury with top-tier amenities.
          <br/>
          • Adventure Activities: Snorkeling, diving, wakeboarding, and more for thrill-seekers.
          <br/> <br/>
          Whether you want a relaxing coastal cruise or an adrenaline-filled day on the water, we'll craft the perfect
          experience for you.
          <br/> <br/>
          Why Choose Us?
          <br/> <br/>
          At Hvar Boating, we pride ourselves on exceptional service and local expertise. Here's what makes us stand
          out:
          <br/> <br/>
          • Extensive Fleet: From motorboats to luxury yachts, we offer options for every need.
          <br/>
          • Personalized Service: Every group is unique, and we tailor your boat hire to match your preferences.
          <br/>
          • Local Insights: Our team knows Hvar inside out and will guide you to its hidden gems.
          <br/>
          • Safety First: Our boats are well-maintained and equipped with all necessary safety gear.
          <br/> <br/>
          Ready to Set Sail?
          <br/> <br/>
          Create memories that last a lifetime with a boat hire in Hvar. Whether you dream of tranquil island hopping or
          action-packed water sports, we provide everything you need for an unforgettable Adriatic adventure.
          Book your boat hire in Hvar today and unlock the hidden treasures of the Adriatic!
        </p>
      </div>
    </div>
    <div class="boat-tours-landing-second">
      <div class="landing-group-tours box" v-for="tour in visibleTours" :key="tour.id">
        <router-link
            :to="{name: 'details', params:{id: tour.id, title: tour.seoTitle, content: tour.seoContent, markup:tour.markup}}">
          <div class="image"
               :style="getBackgroundStyle(tour.image)"
               :ref="`tourImage-${tour.id}`">
            <span role="img" :aria-label="tour.imageAlt || '[hvar boat tours]'"></span>
            <div class="overlay-title">
              <h4 class="hover-title">{{ tour.hoverTitle }}</h4>
              <div class="hover-title-line"></div>
              <p>{{ tour.shortDescription }}</p>
            </div>
            <h4 class="title">{{ tour.title }}</h4>
          </div>
        </router-link>
      </div>
    </div>
    <ContactForm/>
  </div>
</template>

<script>

import tours from '@/data/privateToursData.json'
import emailjs from 'emailjs-com'
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: 'BoatTours',

  components: {
    ContactForm,

  },

  metaInfo() {
    return {
      title: 'Explore Hvar: Top-Rated Boat Tours & Exclusive Offers',
      meta: [
        {name: 'robots', content: 'index,follow'},
        {name: 'description', content: this.metaDescription},
        {property: 'og:title', content: 'Explore Hvar: Top-Rated Boat Tours & Exclusive Offers'},
        {property: 'og:description', content: this.metaDescription},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content: require('@/assets/bluecave.jpg')},
        {property: 'og:url', content: this.currentUrl},
        {name: 'twitter:card', content: 'summary_large_image'},
        {name: 'twitter:title', content: 'Explore Hvar: Top-Rated Boat Tours & Exclusive Offers'},
        {name: 'twitter:description', content: this.metaDescription}
      ],
      link: [
        {rel: 'canonical', href: this.currentUrl},
        {rel: 'favicon', href: 'logo.ico'}
      ],
      script: [{
        type: 'application/ld+json',
        json: this.getJsonLd()
      }]
    }
  },

  data() {
    return {
      videoLoading: true,
      showModal: false,
      isSubmitting: false,
      visibleTours: [],
      boatTours: tours,
      formData: {
        name: '',
        email: '',
        title: '',
        message: ''
      },
      errors: []
    }
  },

  computed: {
    currentUrl() {
      return `https://www.hvarboating.com${this.$route.path}`
    }
  },

  created() {
    this.loadToursInChunks()
  },

  methods: {
    loadToursInChunks() {
      const chunkSize = 4
      let loaded = 0

      const loadNextChunk = () => {
        const chunk = this.boatTours.slice(loaded, loaded + chunkSize)
        this.visibleTours.push(...chunk)
        loaded += chunkSize

        if (loaded < this.boatTours.length) {
          requestAnimationFrame(loadNextChunk)
        }
      }

      loadNextChunk()
    },

    getBackgroundStyle(imagePath) {
      return {
        background: `url(${this.coverImage(imagePath)}) no-repeat center center`,
        backgroundSize: 'cover'
      }
    },

    coverImage(path) {
      return require("@/" + path)
    },

    async handleSubmit(event) {
      if (this.isSubmitting) return

      this.errors = []
      this.isSubmitting = true

      try {
        if (this.validateForm()) {
          await this.sendEmail(event.target)
          this.showSuccessModal()
          this.resetForm()
        }
      } catch (error) {
        console.error('Form submission error:', error)
        this.errors.push('An error occurred. Please try again later.')
      } finally {
        this.isSubmitting = false
      }
    },

    validateForm() {
      const {name, email, title, message} = this.formData

      if (!name) this.errors.push("Name required.")
      if (!email) {
        this.errors.push('Email required.')
      } else if (!this.validEmail(email)) {
        this.errors.push('Valid email required.')
      }
      if (!title) this.errors.push("Title required.")
      if (!message) this.errors.push("Message required.")

      return this.errors.length === 0
    },

    validEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },

    async sendEmail(form) {
      const {name, email, title, message} = this.formData

      return emailjs.sendForm(
          'service_8xsw9aa',
          'template_19ompn2',
          form,
          'user_OReXS1Etru6y4xeTBAltW',
          {name, email, title, message}
      )
    },

    showSuccessModal() {
      this.showModal = true
      setTimeout(this.closeModal, 4000)
    },

    closeModal() {
      this.showModal = false
    },

    resetForm() {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = ''
      })
    },

    getJsonLd() {
      return {
        "@context": "https://schema.org",
        "@type": "TourPackage",
        "name": "Hvar Boat Tours and Excursions",
        "description": this.metaDescription,
        "image": require('@/assets/bluecave.jpg'),
        "provider": {
          "@type": "Organization",
          "name": "Hvar Boating",
          "url": this.currentUrl
        },
        "areaServed": {
          "@type": "Place",
          "name": "Hvar, Croatia"
        }
      }
    }
  }
}
</script>

<style scoped>
/* Only adding styles for new features */
.video-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}
</style>