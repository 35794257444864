<template>
  <div :class="['box', className]">
    <router-link :to="link">
      <div class="images image">
        <div class="overlay-title">
          <h4 class="hover-title">{{ title }}</h4>
          <div class="hover-title-line"></div>
          <p>{{ description }}</p>
        </div>
        <h4 class="title">{{ title }}</h4>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ServiceCard',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: true
    }
  }
}
</script>