import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueAgile from 'vue-agile';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import VModal from 'vue-js-modal';
import VueMeta from 'vue-meta';
import VueSimpleAccordion from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import Vuex from 'vuex';

// Fix Leaflet marker icon issue
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Use Vue plugins
Vue.use(VueSimpleAccordion);
Vue.use(Vuex);
Vue.use(VueAgile);
Vue.use(VModal, {
  dynamicDefaults: {
    styles: 'background-color:#293b5f',
  },
});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

// Create and mount Vue instance
new Vue({
  router,
  render: h => h(App),
  mounted() {
    // Trigger the prerender event for Prerender SPA Plugin
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount('#app');
