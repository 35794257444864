<template>
  <div class="private-tour">
    <div class="private-tour-landing" v-for="tour in privateTour" :key="tour.id">
      <div v-show="proId === tour.id" class="private-tour-landing-inner">
        <!-- Hero Section -->
        <video
            ref="tourVideo"
            :src="tour.videoSrc || ''"
            type="video/mp4"
            :poster="coverImage(tour.image)"
            muted
            playsinline
            loop
            preload="metadata"
        ></video>

        <div class="private-tour-overlay">
          <div class="private-tour-inner">
            <h1>{{ tour.title }}</h1>
            <p>{{ tour.subtitle }}</p>
            <div class="buttons">
              <button
                  class="bokunButton left-button"
                  :data-src="tour.bokunStandardButton"
                  data-testid="widget-book-button"
              >
                Book standard option!
              </button>
              <button
                  class="bokunButton"
                  :data-src="tour.bokunDeluxeButton"
                  data-testid="widget-book-button"
              >
                Book deluxe option!
              </button>
            </div>
          </div>
        </div>

        <!-- Tour Details -->
        <div class="private-tour-details">
          <div class="private-tour-left-details">
            <ul>
              <li class="private-tour-title-no-underline">
                <h4>Tour Summary</h4>
              </li>
              <li v-for="item in tour.tourSummary" :key="item.id">
                <p v-html="item.tourSummaryItem"></p>
              </li>
            </ul>
          </div>

          <div class="private-tour-right-details">
            <ul>
              <li class="private-tour-title-no-underline">
                <h4>Additional information</h4>
              </li>
              <li v-for="addItem in tour.additionalInformation" :key="addItem.id">
                <p v-html="addItem.addInfItem"></p>
              </li>
            </ul>

            <ul class="private-tour-highlight">
              <li class="private-tour-title-no-underline">
                <h4>TOUR HIGHLIGHTS</h4>
              </li>
              <li>
                <p v-html="tour.tourHighlights"></p>
              </li>
            </ul>
          </div>
        </div>

        <!-- Description Section -->
        <div class="private-tour-description">
          <div class="private-tour-desc-right">
            <div
                class="descImage mobile-image"
                :style="getImageStyle(tour.descImageOne)"
            >
              <span role="img" aria-label="hvar tours blue cave"></span>
            </div>
            <div
                class="descImage"
                :style="getImageStyle(tour.descImageTwo)"
            >
              <span role="img" aria-label="boat trips hvar"></span>
            </div>
          </div>

          <div class="private-tour-desc-left">
            <h4>{{ tour.descriptionTitle }}</h4>
            <div class="private-tour-underline"></div>
            <p v-html="tour.description"></p>
          </div>
        </div>

        <!-- Gallery Section -->
        <div class="private-tour-gallery">
          <agile
              :initial-slide="1"
              :options="carouselOptions"
              ref="gallery"
          >
            <img
                v-for="photo in tour.gallery"
                :key="photo.id"
                class="slide"
                :src="coverImage(photo.img)"
                :alt="getImageAlt(tour.title, photo.id)"
                loading="lazy"
            >
            <template #prevButton>
              <i class="fas fa-chevron-left" aria-label="Previous image"></i>
            </template>
            <template #nextButton>
              <i class="fas fa-chevron-right" aria-label="Next image"></i>
            </template>
          </agile>
        </div>

        <!-- Related Tours -->
        <div class="backlink-title">
          <h4>private tours are not your cup of tea?</h4>
        </div>

        <div class="backlink">
          <div class="backlink-inner box">
            <router-link to="/blue-caves-croatia">
              <div class="groupImage image">
                <div class="overlay-title">
                  <h4 class="hover-title">group boat tours</h4>
                  <div class="hover-title-line"></div>
                  <p>Discover Blue cave, green cave, Stiniva bay and palmizana beach</p>
                </div>
                <h4 class="title">group boat tours</h4>
              </div>
            </router-link>
          </div>

          <div class="backlink-inner box">
            <router-link to="/hvar-boat-rental">
              <div class="rentalImage image">
                <div class="overlay-title">
                  <h4 class="hover-title">hvar boat rental</h4>
                  <div class="hover-title-line"></div>
                  <p>Rent a boat with crew in Hvar and explore maze of pakleni islands, unique blue cave, or one of the best beaches in Europe Stiniva</p>
                </div>
                <h4 class="title">hvar boat rental</h4>
              </div>
            </router-link>
          </div>
        </div>

        <!-- Contact Form -->
       <ContactForm/>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { VueAgile } from 'vue-agile'
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal"
import privateToursData from '@/data/privateToursData.json'
import store from '../store'
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: 'PrivateTour',

  components: {
    ContactForm,
    agile: VueAgile
  },

  metaInfo() {
    return {
      title: this.seoTitle,
      meta: [
        { name: 'robots', content: 'index,follow,max-image-preview:large' },
        { name: 'description', content: this.seoContent },
        { property: 'og:title', content: this.seoTitle },
        { property: 'og:description', content: this.seoContent },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://www.hvarboating.com${this.$route.path}` },
        { property: 'og:image', content: this.mainImage },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'keywords', content: 'private boat tour hvar, blue cave private tour, luxury boat tour croatia' }
      ],
      link: [
        { rel: 'canonical', href: `https://www.hvarboating.com${this.$route.path}` },
        { rel: 'icon', type: 'image/x-icon', href: '/logo.ico' }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: this.markups
        }
      ]
    }
  },

  data() {
    const SEOdata = store.getters.getBoatTour(this.$route.params.id)
    return {
      proId: this.$route.params.id,
      seoTitle: SEOdata.seoTitle,
      seoContent: SEOdata.seoContent,
      markups: SEOdata.markup,
      privateTour: privateToursData,
      isSubmitting: false,
      errors: [],
      formData: {
        name: '',
        email: '',
        title: '',
        message: ''
      },
      carouselOptions: {
        navButtons: false,
        infinite: true,
        responsive: [
          {
            breakpoint: 350,
            settings: {
              dots: false,
              navButtons: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              navButtons: true
            }
          },
          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              dots: true,
              infinite: false
            }
          }
        ]
      }
    }
  },

  computed: {
    currentTour() {
      return this.privateTour.find(tour => tour.id === this.proId) || {}
    },
    mainImage() {
      return this.currentTour?.image
          ? `https://www.hvarboating.com/images/${this.currentTour.image}`
          : ''
    },
    isFormValid() {
      const { name, email, title, message } = this.formData
      return name && email && this.validEmail(email) && title && message
    }
  },

  methods: {
    getImageStyle(path) {
      return {
        background: `url(${this.coverImage(path)}) no-repeat center center`,
        backgroundSize: 'cover'
      }
    },

    getImageAlt(tourTitle, photoId) {
      return `${tourTitle} - Photo ${photoId}`
    },

    coverImage(path) {
      try {
        return require(`@/${path}`)
      } catch (e) {
        console.error(`Failed to load image: ${path}`)
        return ''
      }
    },

    validEmail: function(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },

    validateForm: debounce(function() {
      this.errors = []
      const { name, email, title, message } = this.formData

      if (!name) this.errors.push("Name required.")
      if (!email) this.errors.push('Email required.')
      else if (!this.validEmail(email)) this.errors.push('Valid email required.')
      if (!title) this.errors.push("Title required.")
      if (!message) this.errors.push("Message required.")

      return this.errors.length === 0
    }, 300),

    async handleFormSubmit() {
      if (this.isSubmitting || !this.validateForm()) return

      this.isSubmitting = true

      try {
        const formElement = document.querySelector('form')
        await emailjs.sendForm(
            'service_8xsw9aa',
            'template_19ompn2',
            formElement,
            'user_OReXS1Etru6y4xeTBAltW'
        )

        this.$modal.show(Modal)
        setTimeout(() => {
          this.$modal.hideAll()
        }, 4000)

        Object.keys(this.formData).forEach(key => {
          this.formData[key] = ''
        })
      } catch (error) {
        console.error('Form submission error:', error)
        this.errors.push('Failed to send message. Please try again.')
      } finally {
        this.isSubmitting = false
      }
    },

    initializeBokunWidget() {
      const script = document.createElement('script')
      script.setAttribute(
          'src',
          'https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=e01dcccd-6e0d-4ece-81e9-2c85dd34e5ff'
      )
      script.async = true
      script.defer = true
      document.head.appendChild(script)
    }
  },

  mounted() {
    this.initializeBokunWidget()

    // Initialize video
    const video = this.$refs.tourVideo
    if (video) {
      video.play().catch(error => {
        console.log('Video autoplay prevented:', error)
      })
    }
  },

  beforeDestroy() {
    // Cleanup any event listeners or timers here
  }
}
</script>

<style scoped>
/* Add your existing styles here */
.private-tour {
  position: relative;
}

.private-tour-landing-inner {
  position: relative;
}

/* Add transition for smooth form state changes */
input, textarea, button {
  transition: opacity 0.3s ease;
}

input:disabled,
textarea:disabled,
button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Add loading state styles */
.isSubmitting {
  position: relative;
  pointer-events: none;
}

.isSubmitting::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
}

/* Add error state styles */
.error-messages {
  background: rgba(255, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
}

/* Add your remaining styles... */
</style>