<template>
  <div class="weather">
    <div class="weather-landing">
      <video
          src=""
          type="video/mp4" autoplay loop :poster="require('@/assets/mlini.jpg')"></video>
      <div class="weather-overlay">
        <div class="weather-inner">
          <h1>Weather in Hvar Island: The Complete Guide for Tourists (April — October)</h1>
          <div class="underline"></div>
          <p>For perfectly planned vacation</p>
        </div>
      </div>
      <div class="weather-description">
        <div class="weather-desc-right">
          <div class="weatherImage"></div>
        </div>
        <div class="weather-desc-left">
          <h4>hvar weather facts</h4>
          <div class="weather-underline"></div>
          <p>
            Hvar Island, a stunning Croatian gem, offers endless sunshine, azure waters, and rich cultural experiences.
            To make the most of your visit, understanding the island's weather during its peak season (April through
            October) is essential. Here's a detailed guide to help you plan your perfect getaway.
            <br/><br/>
            Hvar Weather Guide (April – October)
            <br/><br/>
            Hvar enjoys a Mediterranean climate, characterized by long, sunny summers and mild autumns. From April to
            October, the weather is ideal for beach days, exploring cultural sites, and taking scenic boat tours to
            nearby islands like Vis, Brač, and Šolta.
            <br/><br/>
            Month-by-Month Weather Guide
            <br/><br/>
          </p>
          <div class="weather-table">
            <table>
              <thead>
              <tr>
                <th>Month</th>
                <th>Daytime Temp</th>
                <th>Sea Temp</th>
                <th>Best For</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>April</td>
                <td>16-20°C (61-68°F)</td>
                <td>15°C (59°F)</td>
                <td>Exploring cultural landmarks, secluded beaches</td>
              </tr>
              <tr>
                <td>May</td>
                <td>20-24°C (68-75°F)</td>
                <td>18°C (64°F)</td>
                <td>Beach days, boat tours, and sightseeing</td>
              </tr>
              <tr>
                <td>June-August</td>
                <td>28-33°C (82-91°F)</td>
                <td>23-25°C (73-77°F)</td>
                <td>Festivals, Blue Cave tours, swimming</td>
              </tr>
              <tr>
                <td>September</td>
                <td>24-27°C (75-80°F)</td>
                <td>23°C (73°F)</td>
                <td>Quiet beaches, swimming, sailing</td>
              </tr>
              <tr>
                <td>October</td>
                <td>18-22°C (64-72°F)</td>
                <td>20°C (68°F)</td>
                <td>Peaceful retreats, scenic boat rides</td>
              </tr>
              </tbody>
            </table>
          </div>

          <p>
            <br/><br/>
            Highlights for Key Months
            <br/><br/>
            September in Hvar
            • Daytime Temperatures: 24-27°C (75-80°F), perfect for enjoying the beaches and outdoor activities.
            <br/>
            • Sea Temperature: A warm 23°C (73°F), ideal for swimming at beaches like Dubovica and Golden Horn.
            <br/>
            • Things to Do: Take a Blue Cave tour, explore nearby islands, or relax on the serene beaches as summer
            crowds thin out.
            <br/><br/>
            October in Hvar
            <br/>
            • Daytime Temperatures: 18-22°C (64-72°F), with cool and pleasant evenings.
            <br/>
            • Sea Temperature: 20°C (68°F), still suitable for swimming and sailing.
            <br/>
            • Things to See: Visit Vis Island or Šolta, enjoy peaceful walks through Hvar Town, or take a boat tour to
            the Blue Grotto.
            <br/><br/>
            Top Beaches and Activities in Hvar
            <br/><br/>
            Hvar boasts pristine beaches and a range of exciting activities to suit every traveler.
            <br/>
            • Beaches: Don't miss Dubovica Beach, Kašjuni Beach, and Ježinac for crystal-clear waters and relaxing
            vibes.
            <br/>
            • Blue Cave Tours: Experience the magic of the Blue Cave with tours departing from Hvar, Split, or
            Dubrovnik.
            <br/>
            • Nearby Islands: Explore the untouched beauty of Brač and Šolta, or embark on a day trip to Vis Island.
            <br/>
            • Hvar Town: Wander through its historic streets, admire Venetian architecture, and soak up the lively
            ambiance.
            <br/><br/>
            Why Visit Hvar Between April and October?
            <br/><br/>
            • Perfect Weather: Warm temperatures, calm seas, and plenty of sunshine make this period ideal for outdoor
            adventures.
            <br/>
            • Seasonal Flexibility: Summer offers vibrant festivals and lively beaches, while autumn provides tranquil
            escapes with fewer crowds.
            <br/>
            • Endless Activities: Swim, sail, or rent a private boat to discover hidden coves and scenic coastal spots.
            <br/><br/>
            Plan Your Hvar Adventure
            <br/>
            Whether you're seeking a bustling summer escape or a peaceful autumn retreat, Hvar Island won't disappoint.
            Swim in turquoise waters, explore stunning caves, and relax on sun-drenched beaches.
            Ready to start planning? Visit Hvar Boating for the best boat rentals, tours, and local attractions. Your
            unforgettable Croatian adventure awaits!
          </p>
        </div>
      </div>
      <ContactForm/>
    </div>
  </div>
</template>
<script>
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal"
import ContactForm from "@/components/ContactForm.vue";

export default {
  components: {ContactForm},
  metaInfo() {
    return {
      title: 'Interesting Hvar Weather Facts',
      meta: [
        {name: 'robots', content: 'index,follow'},
        {
          name: 'description',
          content: 'These are some Hvar island weather facts to help you organize your vacation or boat tour in Hvar'
        },
        {property: 'og:title', content: 'Hvar Weather Guide'},
        {
          property: 'og:description',
          content: 'These are some Hvar island weather facts to help you organize your vacation or boat tour in Hvar'
        },
        {property: 'og:type', content: 'website'},
        {property: 'og:url', content: `https://www.hvarboating.com${this.$route.path}`}
      ],
      link: [
        {rel: 'favicon', href: 'logo.ico'}
      ],
      script: [{
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@type": "TouristDestination",
          "name": "Hvar Island",
          "description": "These are some Hvar island weather facts to help you organize your vacation or boat tour in Hvar",
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": "43.1729",
            "longitude": "16.4413"
          }
        }
      }]
    }
  },

  data() {
    return {
      name: '',
      email: '',
      title: '',
      message: '',
      errors: [],
      isSubmitting: false
    }
  },

  mounted() {
    this.loadWidgetScript()
  },

  methods: {
    loadWidgetScript() {
      const plugin = document.createElement("script")
      plugin.setAttribute(
          "src",
          "https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=e01dcccd-6e0d-4ece-81e9-2c85dd34e5ff"
      )
      plugin.async = true
      document.head.appendChild(plugin)
    },

    checkForm(e) {
      e.preventDefault()
      this.errors = []

      if (!this.name) {
        this.errors.push("Name required.")
      }
      if (!this.email) {
        this.errors.push('Email required.')
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.')
      }
      if (!this.title) {
        this.errors.push("Title required.")
      }
      if (!this.message) {
        this.errors.push("Message required.")
      }

      if (!this.errors.length) {
        this.isSubmitting = true
        try {
          emailjs.sendForm(
              'service_8xsw9aa',
              'template_19ompn2',
              e.target,
              'user_OReXS1Etru6y4xeTBAltW',
              {
                name: this.name,
                email: this.email,
                title: this.title,
                message: this.message
              }
          ).then(() => {
            this.$modal.show(Modal)
            setTimeout(() => {
              this.$modal.hideAll()
            }, 4000)
            this.resetForm()
          }).catch(error => {
            console.error('Email send error:', error)
            this.errors.push('Failed to send email. Please try again.')
          }).finally(() => {
            this.isSubmitting = false
          })
        } catch (error) {
          console.error('Form submission error:', error)
          this.isSubmitting = false
        }
      }
    },

    validEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },

    resetForm() {
      this.name = ''
      this.email = ''
      this.title = ''
      this.message = ''
    }
  }
}
</script>

<style scoped>
.weather-table {
  margin: 1rem 0;
}

.weather-table table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.weather-table th,
.weather-table td {
  padding: 0.75rem;
  text-align: left;
  border: 1px solid #ddd;
  color: white;
  text-transform: uppercase;
}

.weather-table th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: black;
}

@media (max-width: 768px) {
  .weather-table {
    overflow-x: auto;
    display: block;
  }
}
</style>