import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

// SEO metadata for static routes
const seoMetadata = {
  home: {
    title: 'Hvar Boating - Premium Boat Tours & Rentals in Croatia',
    description: 'Experience the best of Croatian islands with Hvar Boating. Discover Blue Caves, private boat tours, rentals, and transfers in Hvar, Croatia.',
    keywords: 'hvar boat tours, croatia boat rental, blue caves croatia, hvar boat transfer'
  },
  blueCaves: {
    title: 'Blue Caves Croatia Tour from Hvar - Group Tours | Hvar Boating',
    description: 'Join our Blue Caves Croatia group tour from Hvar. Visit Biševo Blue Cave, Vis, Stiniva Bay, and Palmižana. Book your adventure today!',
    keywords: 'blue caves croatia, blue cave tour, hvar group tours, bisevo blue cave'
  },
  boatTours: {
    title: 'Private Boat Tours Hvar - Customized Croatian Adventures',
    description: 'Explore Croatian islands with our private boat tours from Hvar. Custom itineraries, professional skippers, and unforgettable experiences.',
    keywords: 'private boat tours hvar, custom boat tour croatia, hvar private tours'
  },
  boatRentals: {
    title: 'Boat Rental Hvar - Speed & Luxury Boat Hire | Hvar Boating',
    description: 'Rent a boat in Hvar and explore Croatian islands at your pace. Choose from our fleet of speed boats and luxury vessels. No license required options available.',
    keywords: 'boat rental hvar, speed boat hire, luxury boat rental croatia'
  },
  boatTransfers: {
    title: 'Split to Hvar Boat Transfer - Fast & Comfortable | Hvar Boating',
    description: 'Book direct boat transfers from Split to Hvar. Fast, reliable, and comfortable transfer service with professional crew.',
    keywords: 'split to hvar transfer, hvar boat transfer, split hvar boat'
  }
}

// Dynamic route validation
const validPrivateTourSlugs = [
  'blue-cave-tour-from-hvar',
  'best-beaches-in-hvar-private',
  'zlatni-rat-brac-island',
  'boat-party-tour'
]

const validBoatRentalSlugs = [
  'speed-boat-hire-hvar-flyer747',
  'luxury-boat-hire-hvar-tornado38'
]

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
    meta: {
      sitemap: {
        priority: 1.0,
        changefreq: 'daily'
      },
      ...seoMetadata.home,
      schema: {
        '@context': 'https://schema.org',
        '@type': 'TravelAgency',
        'name': 'Hvar Boating',
        'url': 'https://www.hvarboating.com',
        'description': seoMetadata.home.description,
        'address': {
          '@type': 'PostalAddress',
          'streetAddress': 'jurja matijevica 17',
          'addressLocality': 'Hvar',
          'postalCode': '21450',
          'addressCountry': 'HR'
        }
      }
    }
  },
  {
    path: '/blue-caves-croatia',
    name: 'GroupToursDetails',
    component: () => import(/* webpackChunkName: "groupTourDetails" */ '../views/GroupToursDetails'),
    meta: {
      sitemap: {
        priority: 0.9,
        changefreq: 'daily'
      },
      ...seoMetadata.blueCaves,
      schema: {
        '@context': 'https://schema.org',
        '@type': 'TourPackage',
        'name': 'Blue Caves Croatia Group Tour',
        'description': seoMetadata.blueCaves.description,
        'tourPackageType': 'Group'
      }
    }
  },
  {
    path: '/hvar-boat-tours-top-rated-adventures',
    name: 'BoatTours',
    component: () => import(/* webpackChunkName: "boatTours" */ '../views/BoatTours'),
    meta: {
      sitemap: {
        priority: 0.9,
        changefreq: 'daily'
      },
      ...seoMetadata.boatTours
    }
  },
  {
    path: '/hvar-boat-hire-adriatics-hidden-gems',
    name: 'BoatRentals',
    component: () => import(/* webpackChunkName: "boatRentals" */ '../views/BoatRentals'),
    meta: {
      sitemap: {
        priority: 0.8,
        changefreq: 'weekly'
      },
      ...seoMetadata.boatRentals
    }
  },
  {
    path: '/from-split-to-Hvar-transfer',
    name: 'BoatTransfers',
    component: () => import(/* webpackChunkName: "boatTransfers" */ '../views/BoatTransfers'),
    meta: {
      sitemap: {
        priority: 0.8,
        changefreq: 'weekly'
      },
      ...seoMetadata.boatTransfers
    }
  },
  {
    path: '/hvar-weather',
    name: 'Weather',
    component: () => import(/* webpackChunkName: "weather" */ '../views/Weather'),
    meta: {
      sitemap: {
        priority: 0.6,
        changefreq: 'daily'
      }
    }
  },
  {
    path: '/frequently-asked-questions',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/Faq'),
    meta: {
      sitemap: {
        priority: 0.7,
        changefreq: 'weekly'
      }
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact'),
    meta: {
      sitemap: {
        priority: 0.8,
        changefreq: 'monthly'
      }
    }
  },
  {
    path: '/:id',
    name: 'details',
    props: true,
    component: () => import(/* webpackChunkName: "privateTours" */ '../views/PrivateToursDetails'),
    meta: {
      sitemap: {
        slugs: validPrivateTourSlugs,
        priority: 0.8,
        changefreq: 'weekly'
      }
    },
    beforeEnter: (to, from, next) => {
      if (validPrivateTourSlugs.includes(to.params.id)) {
        next()
      } else {
        next('/404')
      }
    }
  },
  {
    path: '/rent-a-boat-hvar/:id',
    name: 'rentals',
    props: true,
    component: () => import(/* webpackChunkName: "boatRentDetails" */ '../views/BoatRentDetails'),
    meta: {
      sitemap: {
        slugs: validBoatRentalSlugs,
        priority: 0.8,
        changefreq: 'weekly'
      }
    },
    beforeEnter: (to, from, next) => {
      if (validBoatRentalSlugs.includes(to.params.id)) {
        next()
      } else {
        next('/404')
      }
    }
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound'),
    meta: {
      sitemap: {
        exclude: true
      }
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else {
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  }
})

   // Navigation guards for analytics and meta tags
router.beforeEach((to, from, next) => {
  // Track page view - Check if gtag exists and is a function
  if (typeof window !== 'undefined' && window.gtag && typeof window.gtag === 'function') {
    window.gtag('config', 'G-4K63REHZ85', {
      page_path: to.path,
      page_title: to.meta.title,
      page_location: window.location.origin + to.path
    })
  }

  // Add dynamic meta tags
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }

  next()
})

export default router