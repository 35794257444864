<template>
  <div class="alert-message" :class="type">
    <strong>Please correct the following error(s):</strong>
    <ul>
      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AlertMessage',
  props: {
    errors: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: 'error'
    }
  }
}
</script>

<style scoped>
.alert-message {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.error {
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #ef9a9a;
}
</style>