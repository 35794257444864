<template>
  <div class="boat-rental">
    <div v-for="rental in boatRentals" :key="rental.id" class="boat-rental-landing">
      <div v-if="proId === rental.id" class="boat-rental-landing-inner">
        <!-- Video with loading state -->
        <div v-if="videoLoading" class="video-loader">Loading...</div>
        <video
            ref="rentalVideo"
            :src="coverImage(rental.video)"
            type="video/mp4"
            :poster="coverImage(rental.image)"
            @loadstart="videoLoading = true"
            @canplay="handleVideoLoad"
            autoplay
            loop
            muted
        ></video>

        <div class="boat-rental-overlay">
          <div class="boat-rental-inner">
            <h1>{{ rental.title }}</h1>
            <p>{{ rental.subtitle }}</p>
          </div>
        </div>

        <div class="boat-rental-details">
          <div class="boat-rental-left-details">
            <ul>
              <li class="boat-rental-title-no-underline"><h4>Details</h4></li>
              <li v-for="item in rental.tourSummary" :key="item.id">
                <p v-html="item.tourSummaryItem"></p>
              </li>
            </ul>
          </div>
          <div class="boat-rental-right-details">
            <ul>
              <li class="boat-rental-title-no-underline"><h4>Specifications</h4></li>
              <li v-for="addItem in rental.additionalInformation" :key="addItem.id">
                <p v-html="addItem.addInfItem"></p>
              </li>
            </ul>
          </div>
        </div>

        <div class="boat-rental-description">
          <div class="boat-rental-desc-right">
            <div
                class="descImage mobile-image"
                :style="getBackgroundStyle(rental.descImageOne)"
            >
              <span role="img" :aria-label="rental.imageAlt || 'boat rent hvar'"></span>
            </div>
            <div
                class="descImage"
                :style="getBackgroundStyle(rental.descImageTwo)"
            >
              <span role="img" :aria-label="rental.imageAlt || 'hvar boat rental'"></span>
            </div>
          </div>
          <div class="boat-rental-desc-left">
            <h4>Hvar Island – Boat rental Hvar Details</h4>
            <div class="boat-rental-underline"></div>
            <p v-html="rental.description"></p>
          </div>
        </div>

        <!-- Gallery with loading states -->
        <div class="boat-rental-gallery">
          <agile
              :initial-slide="1"
              :options="galleryOptions"
              ref="gallery"
              @after-change="handleSlideChange"
          >
            <div
                v-for="photo in rental.gallery"
                :key="photo.id"
                class="slide-wrapper"
            >
              <img
                  :src="coverImage(photo.img)"
                  :alt="photo.alt || 'hvar boat rental'"
                  loading="lazy"
                  class="slide"
                  @load="handleImageLoad(photo.id)"
              >
              <div
                  v-if="loadingImages[photo.id]"
                  class="slide-loader"
              >
                Loading...
              </div>
            </div>
            <template slot="prevButton">
              <i class="fas fa-chevron-left" aria-label="Previous slide"></i>
            </template>
            <template slot="nextButton">
              <i class="fas fa-chevron-right" aria-label="Next slide"></i>
            </template>
          </agile>
        </div>

        <!-- Contact Form -->
        <ContactForm/>
      </div>
    </div>

  </div>
</template>

<script>
import boatRentalsData from '@/data/boatRentalsData.json'
import { VueAgile } from 'vue-agile'
import emailjs from 'emailjs-com'
import store from "@/store"
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: 'BoatRentalDetails',

  components: {
    ContactForm,
    agile: VueAgile,
  },

  metaInfo() {
    const rental = this.currentRental;
    return {
      title: this.seoTitle,
      titleTemplate: '%s | Hvar Boat Rental',
      meta: [
        { name: 'robots', content: 'index,follow' },
        { name: 'description', content: this.seoContent },
        { property: 'og:title', content: this.seoTitle },
        { property: 'og:description', content: this.seoContent },
        { property: 'og:type', content: 'product' },
        { property: 'og:image', content: rental ? this.coverImage(rental.image) : '' },
        { property: 'og:url', content: this.currentUrl },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.seoTitle },
        { name: 'twitter:description', content: this.seoContent },
        { name: 'twitter:image', content: rental ? this.coverImage(rental.image) : '' }
      ],
      link: [
        { rel: 'canonical', href: this.currentUrl },
        { rel: 'favicon', href: 'logo.ico' }
      ],
      script: [{
        type: 'application/ld+json',
        json: this.getJsonLd()
      }]
    }
  },

  data() {
    const SEOdata = store.getters.getBoatRental(this.$route.params.id);
    return {
      boatRentals: boatRentalsData,
      proId: this.$route.params.id,
      seoTitle: SEOdata.seoTitle,
      seoContent: SEOdata.seoContent,
      markups: SEOdata.markup,
      videoLoading: true,
      loadingImages: {},
      showModal: false,
      isSubmitting: false,
      formData: {
        name: '',
        email: '',
        title: '',
        message: ''
      },
      errors: [],
      galleryOptions: {
        navButtons: false,
        responsive: [
          {
            breakpoint: 350,
            settings: {
              dots: false,
              navButtons: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              navButtons: true
            }
          },
          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              dots: true,
              infinite: false
            }
          }
        ]
      }
    }
  },

  computed: {
    currentUrl() {
      return `https://www.hvarboating.com${this.$route.path}`
    },

    currentRental() {
      return this.boatRentals.find(rental => rental.id === this.proId)
    }
  },

  methods: {
    handleVideoLoad() {
      this.videoLoading = false
    },

    handleImageLoad(imageId) {
      this.$set(this.loadingImages, imageId, false)
    },


    getBackgroundStyle(imagePath) {
      return {
        background: `url(${this.coverImage(imagePath)}) no-repeat center center`,
        backgroundSize: 'cover'
      }
    },

    coverImage(path) {
      return require("@/" + path)
    },

    async handleSubmit(event) {
      if (this.isSubmitting) return

      this.errors = []
      this.isSubmitting = true

      try {
        if (this.validateForm()) {
          await this.sendEmail(event.target)
          this.showSuccessModal()
          this.resetForm()
        }
      } catch (error) {
        console.error('Form submission error:', error)
        this.errors.push('An error occurred. Please try again later.')
      } finally {
        this.isSubmitting = false
      }
    },

    validateForm() {
      const { name, email, title, message } = this.formData

      if (!name) this.errors.push("Name required.")
      if (!email) {
        this.errors.push('Email required.')
      } else if (!this.validEmail(email)) {
        this.errors.push('Valid email required.')
      }
      if (!title) this.errors.push("Title required.")
      if (!message) this.errors.push("Message required.")

      return this.errors.length === 0
    },

    validEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },

    async sendEmail(form) {
      const { name, email, title, message } = this.formData

      return emailjs.sendForm(
          'service_8xsw9aa',
          'template_19ompn2',
          form,
          'user_OReXS1Etru6y4xeTBAltW',
          { name, email, title, message }
      )
    },

    showSuccessModal() {
      this.showModal = true
      setTimeout(this.closeModal, 4000)
    },

    closeModal() {
      this.showModal = false
    },

    resetForm() {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = ''
      })
    },

    getJsonLd() {
      const rental = this.currentRental
      if (!rental) return this.markups

      return {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": rental.title,
        "description": this.seoContent,
        "image": this.coverImage(rental.image),
        "brand": {
          "@type": "Brand",
          "name": "Hvar Boat Rental"
        },
        "offers": {
          "@type": "Offer",
          "availability": "https://schema.org/InStock",
          "priceSpecification": {
            "@type": "PriceSpecification",
            "priceCurrency": "EUR"
          }
        }
      }
    }
  }
}
</script>

<style scoped>
/* Only adding styles for new features */
.video-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.slide-wrapper {
  position: relative;
}

.slide-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.error-container {
  margin-bottom: 1rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>