<template>
  <div class="not-found">
    <div class="not-found-content">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>Sorry, we couldn't find the page you're looking for.</p>
      <div class="navigation-options">
        <router-link to="/" class="home-button">Return to Home</router-link>
        <div class="quick-links">
          <h3>Popular Destinations:</h3>
          <ul>
            <li>
              <router-link to="/blue-caves-croatia">Blue Caves Tours</router-link>
            </li>
            <li>
              <router-link to="/hvar-boat-tours-top-rated-adventures">Private Boat Tours</router-link>
            </li>
            <li>
              <router-link to="/hvar-boat-hire-adriatics-hidden-gems">Boat Rentals</router-link>
            </li>
            <li>
              <router-link to="/contact">Contact Us</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  metaInfo: {
    title: '404 Not Found - Hvar Boating',
    meta: [
      { name: 'robots', content: 'noindex' }
    ]
  }
}
</script>

<style scoped>
.not-found {
  min-height: calc(100vh - 200px);
  background: url("../assets/bluecave.jpg") center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  position: relative;
}

.not-found::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(41, 59, 95, 0.9);
}

.not-found-content {
  position: relative;
  text-align: center;
  color: white;
  max-width: 600px;
  width: 100%;
  padding: 40px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  backdrop-filter: blur(10px);
}

h1 {
  font-family: 'Futura', sans-serif;
  font-size: 100px;
  margin: 0;
  font-weight: 400;
  color: white;
}

h2 {
  font-family: 'Futura', sans-serif;
  font-size: 36px;
  margin: 20px 0;
  font-weight: 400;
  text-transform: uppercase;
}

p {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: 300;
}

.navigation-options {
  margin-top: 40px;
}

.home-button {
  display: inline-block;
  padding: 15px 30px;
  background: transparent;
  border: 2px solid white;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Futura', sans-serif;
  font-weight: 400;
  margin-bottom: 30px;
  transition: all 0.3s ease;
}

.home-button:hover {
  background: white;
  color: rgb(41, 59, 95);
}

.quick-links {
  margin-top: 30px;
}

.quick-links h3 {
  font-family: 'Futura', sans-serif;
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 400;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 10px 0;
}

li a {
  color: white;
  text-decoration: none;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 300;
  transition: opacity 0.3s ease;
}

li a:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  h1 {
    font-size: 80px;
  }

  h2 {
    font-size: 28px;
  }

  .not-found-content {
    padding: 30px 20px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 24px;
  }
}
</style>