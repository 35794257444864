<template>
  <div class="boat-rental">
    <div class="boat-rental-landing">
      <!-- Lazy load video with loading indicator -->
      <div v-if="videoLoading" class="video-loader">Loading...</div>

      <video
          ref="boatVideo"
          :src="videoUrl"
          type="video/mp4"
          :poster="require('@/assets/rentCover.jpg')"
          loading="lazy"
          @loadstart="videoLoading = true"
          @canplay="videoLoading = false"
          @loadeddata="videoLoading = false"
          playsinline
          muted
          autoplay
      >
        <source type="video/mp4">
        Your browser does not support the video tag.
      </video>

      <div class="boat-rental-overlay">
        <div class="boat-rental-inner">
          <h1>{{ pageTitle }}</h1>
          <p>rent a boat with the crew</p>
        </div>
      </div>

      <div class="boat-rental-small-bar">
        <h2>{{ subTitle }}</h2>
        <p>
          Discover the unspoiled Adriatic coast on your own terms! Boat hire in Hvar is the ultimate way to explore
          hidden beaches, crystal-clear bays, and the vibrant marine life of this enchanting island. Whether you’re
          drawn to secluded coves or lively coastal towns, we specialize in creating custom boat excursions and private
          yacht charters tailored to your dream adventure.
          <br/><br/>
          Why Choose Boat Hire in Hvar?
          <br/><br/>
          Hvar is one of Croatia’s most sought-after destinations, and there’s no better way to explore it than by boat.
          With boat hire, you can escape the crowds and uncover Hvar’s most beautiful and private spots. Swim in
          turquoise waters, relax on deserted beaches, or cruise around the iconic Pakleni Islands.
          <br/><br/>
          Our diverse fleet includes:
          <br/><br/>
          • Small motorboats perfect for day trips.
          <br/>
          • Luxury yachts offering a high-end cruising experience.
          <br/>
          No matter your preferences, we have the perfect boat for your Hvar adventure.
          <br/>
          Discover Hvar’s Hidden Gems
          <br/><br/>
          Renting a boat in Hvar unveils the Adriatic’s best-kept secrets, including:
          <br/><br/>
          • Pakleni Islands: A picturesque archipelago ideal for sunbathing, swimming, and snorkeling.
          <br/>
          • Stiniva Cove: A secluded gem surrounded by dramatic cliffs, only accessible by boat.
          <br/>
          • Milna Beach: A peaceful haven with clear waters, perfect for swimming or picnicking.
          <br/><br/>
          Our well-maintained fleet and professional guidance ensure you’ll explore these natural wonders in style and
          comfort.
          <br/>
          Tailored Experiences for Every Traveler
          <br/>
          At our Hvar adventure agency, we design personalized itineraries for all travelers—whether it’s your first
          boat rental or you’re a seasoned sailor.
          <br/>
          Our services include:
          <br/><br/>
          • Private Boat Excursions: Customize your route and discover hidden treasures.
          <br/>
          • Group Boat Trips: Perfect for friends, families, or celebrations.
          <br/>
          • Yacht Charters: Experience ultimate luxury with top-tier amenities.
          <br/>
          • Adventure Activities: Snorkeling, diving, wakeboarding, and more for thrill-seekers.
          <br/>
          Whether you want a relaxing coastal cruise or an adrenaline-filled day on the water, we’ll craft the perfect
          experience for you.
          <br/>
          Why Choose Us?
          <br/><br/>
          At Hvar Boating, we pride ourselves on exceptional service and local expertise. Here’s what makes us stand
          out:
          <br/><br/>
          • Extensive Fleet: From motorboats to luxury yachts, we offer options for every need.
          <br/>
          • Personalized Service: Every group is unique, and we tailor your boat hire to match your preferences.
          <br/>
          • Local Insights: Our team knows Hvar inside out and will guide you to its hidden gems.
          <br/>
          • Safety First: Our boats are well-maintained and equipped with all necessary safety gear.
          <br/>
          <br/>
          Ready to Set Sail?
          <br/><br/>
          Create memories that last a lifetime with a boat hire in Hvar. Whether you dream of tranquil island hopping or
          action-packed water sports, we provide everything you need for an unforgettable Adriatic adventure.
          Book your boat hire in Hvar today and unlock the hidden treasures of the Adriatic!


        </p>
      </div>

      <!-- Use virtual scrolling for better performance with large lists -->
      <div class="boat-rental-landing-second">
        <template v-for="rent in visibleBoatRentals">
          <div :key="rent.id" class="landing-group-tours box">
            <router-link
                :to="{
                name: 'rentals',
                params: {
                  id: rent.id,
                  title: rent.seoTitle,
                  content: rent.seoContent,
                  markup: rent.markup
                }
              }"
            >
              <div
                  class="image"
                  :style="getBackgroundStyle(rent.image)"
                  :ref="`boatImage-${rent.id}`"
              >
                <span role="img" :aria-label="rent.imageAlt || '[hvar boat rental]'"></span>
                <div class="overlay-title">
                  <h4 class="hover-title">{{ rent.title }}</h4>
                  <div class="hover-title-line"></div>
                  <p>{{ rent.shortDescription }}</p>
                </div>
                <h4 class="title">{{ rent.titleTwo }}</h4>
              </div>
            </router-link>
          </div>
        </template>
      </div>
      <ContactForm/>
    </div>

  </div>
</template>

<script>
import {debounce} from 'lodash'
import boatRentalsData from '@/data/boatRentalsData.json'
import emailjs from 'emailjs-com'

import tornadoVideo from '@/assets/tornado.mp4'
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: 'BoatRental',

  components: {
    ContactForm,


  },

  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        {name: 'robots', content: 'index,follow'},
        {name: 'description', content: this.metaDescription},
        {property: 'og:title', content: this.pageTitle},
        {property: 'og:description', content: this.metaDescription},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content: this.ogImage},
        {name: 'twitter:card', content: 'summary_large_image'},
        {name: 'twitter:title', content: this.pageTitle},
        {name: 'twitter:description', content: this.metaDescription},
        {name: 'twitter:image', content: this.ogImage}
      ],
      link: [
        {rel: 'canonical', href: `https://www.hvarboating.com${this.$route.path}`},
        {rel: 'favicon', href: 'logo.ico'}
      ],
      script: [{
        type: 'application/ld+json',
        json: this.getJsonLd()
      }]
    }
  },

  data() {
    return {
      boatRent: [],
      videoLoading: true,
      videoUrl: tornadoVideo, // Add your video URL here
      showModal: false,
      isSubmitting: false,
      visibleBoatRentals: [],
      pageTitle: 'Unforgettable Adventures on Hvar with Premium Boat Hire ',
      subTitle: 'Tailoring Your Journey: Customizable Boat Hire Services',
      description: "",
      metaDescription: 'Dive into the crystal-clear Adriatic with our top-rated Hvar Boat Hire. Discover hidden coves, majestic cliffs, and personalized services. Book your voyage now!',
      ogImage: require('@/assets/rentCover.jpg'),
      formData: {
        name: '',
        email: '',
        title: '',
        message: ''
      },
      errors: [],
      observer: null
    }
  },

  computed: {
    hasErrors() {
      return this.errors.length > 0
    }
  },

  created() {
    // Load data in chunks for better initial load performance
    this.loadBoatRentalsInChunks()
  },

  mounted() {
    this.setupIntersectionObserver()
    window.addEventListener('scroll', this.handleScroll)
    // Set initial video loading state to false if no video URL is provided
    if (!this.videoUrl) {
      this.videoLoading = false
    }
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    loadBoatRentalsInChunks() {
      const chunkSize = 5
      let loaded = 0

      const loadNextChunk = () => {
        const chunk = boatRentalsData.slice(loaded, loaded + chunkSize)
        this.boatRent.push(...chunk)
        this.visibleBoatRentals = [...this.boatRent]
        loaded += chunkSize

        if (loaded < boatRentalsData.length) {
          setTimeout(loadNextChunk, 100)
        }
      }

      loadNextChunk()
    },

    setupIntersectionObserver() {
      this.observer = new IntersectionObserver(
          (entries) => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                const imageDiv = entry.target
                if (imageDiv.dataset.src) {
                  imageDiv.style.backgroundImage = `url(${imageDiv.dataset.src})`
                  imageDiv.dataset.src = ''
                  this.observer.unobserve(imageDiv)
                }
              }
            })
          },
          {
            rootMargin: '50px 0px',
            threshold: 0.1
          }
      )

      this.$nextTick(() => {
        document.querySelectorAll('.image[data-src]').forEach(img => {
          this.observer.observe(img)
        })
      })
    },

    handleScroll: debounce(function () {
      // Implement infinite scroll or lazy loading logic here
    }, 100),

    getBackgroundStyle(imagePath) {
      return {
        background: `url(${this.coverImage(imagePath)}) no-repeat center center`,
        backgroundSize: 'cover'
      }
    },

    coverImage(path) {
      return require("@/" + path)
    },

    async handleSubmit(e) {
      if (this.isSubmitting) return

      this.errors = []
      this.isSubmitting = true

      try {
        if (this.validateForm()) {
          await this.sendEmail(e)
          this.showSuccessModal()
          this.resetForm()
        }
      } catch (error) {
        console.error('Form submission error:', error)
        this.errors.push('An error occurred. Please try again later.')
      } finally {
        this.isSubmitting = false
      }
    },

    validateForm() {
      const {name, email, title, message} = this.formData

      if (!name) this.errors.push("Name required.")
      if (!email) {
        this.errors.push('Email required.')
      } else if (!this.validEmail(email)) {
        this.errors.push('Valid email required.')
      }
      if (!title) this.errors.push("Title required.")
      if (!message) this.errors.push("Message required.")

      return this.errors.length === 0
    },

    validEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },

    async sendEmail(e) {
      const {name, email, title, message} = this.formData

      return emailjs.sendForm(
          'service_8xsw9aa',
          'template_19ompn2',
          e.target,
          'user_OReXS1Etru6y4xeTBAltW',
          {name, email, title, message}
      )
    },

    showSuccessModal() {
      this.showModal = true
      setTimeout(() => {
        this.closeModal()
      }, 4000)
    },

    closeModal() {
      this.showModal = false
    },

    resetForm() {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = ''
      })
    },

    hasError(field) {
      return this.errors.some(error =>
          error.toLowerCase().includes(field.toLowerCase())
      )
    },

    getJsonLd() {
      return {
        "@context": "https://schema.org",
        "@type": "TouristService",
        "name": this.pageTitle,
        "description": this.metaDescription,
        "image": this.ogImage,
        "priceRange": "€€€",
        "areaServed": {
          "@type": "Place",
          "name": "Hvar, Croatia",
          "address": {
            "@type": "PostalAddress",
            "addressCountry": "Croatia",
            "addressLocality": "Hvar"
          }
        },
        "offers": {
          "@type": "Offer",
          "availability": "https://schema.org/InStock",
          "priceSpecification": {
            "@type": "PriceSpecification",
            "priceCurrency": "EUR"
          }
        },
        "provider": {
          "@type": "Organization",
          "name": "Your Company Name",
          "logo": "https://www.hvarboating.com/logo.png"
        }
      }
    }
  }
}
</script>

<style scoped>
.video-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 4px;
}

.error {
  border-color: red !important;
}

.error-container {
  background: #fff3f3;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>